import React from 'react';

function Gmap() {
    return (
        <>
            <iframe
                src="https://maps.google.com/maps?hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                width="100%"
                height="600"
                frameBorder="0"
                title="f"
                style={{ border: 0 }}
                allowFullScreen
            >

            </iframe>
        </>
    )
}
export default Gmap;