import React, { useState } from 'react';
 

function BasicInformation(props) {
// const [category,setCategory]=useState();
const [fault,setFault]=useState("");
const [technicianPrice]=useState(["350","600"]);
 const fault1=(f)=>{
     setFault(f);
     props.onSubmit(f);
 }
 const handleIndex=(i)=>{
      props.onDelete(i);
      setFault("");
 }
let {partName,description,partNo,skuNo,length,breadth,height,weight,faultType,MRP,bestPrice,technician,productModel,category,brandName}=props?.sparePart;
let {categories}=props;

let product1=props?.products?.data?.filter(f1=>f1?.brandName===brandName)?.map(m1=>({status:"ACTIVE",categoryName:m1?.productCategory}));
let category1=categories?.filter(f1=>f1?.brandName===brandName)?.map(m1=>({status:"ACTIVE", categoryName:m1?.categoryName}));
let merge=product1.concat(category1);
let unique1=Array.from(new Set(merge.map(JSON.stringify))).map(JSON.parse);

let categories1=(props?.user?.role==="RESELLER" || props?.user?.role==="ADMIN") ? unique1 : categories;
let products1=props?.products?.data?.filter(p1=>p1?.productCategory===category && p1?.brandName===brandName)

    return (
        <>
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">Basic information</h6>
            </div>
            <div className="card-body">
                <form>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-12">
                            <label className="form-label"> Spare Part Name</label>
                            <input type="text" className="form-control" name='partName' value={partName} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.partName ? <div className='text-danger'>{props?.errors?.partName }</div> :"" }
                        </div>
                        <div className="col-md-6">
                            <label className="form-label"> Spare Part No.</label>
                            <input type="text" className="form-control" name='partNo' value={partNo} onChange={(e) => {props.onChange(e) }} />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label"> SKU No.</label>
                            <input type="text" className="form-control" name='skuNo' value={skuNo} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.skuNo ? <div className='text-danger'>{props?.errors?.skuNo }</div> :"" }
                       
                        </div>
                        <div className="col-md-3">
                            <label className="form-label"> Length <span className='text-muted'> (in cm)</span></label>
                            <input type="text" className="form-control" name='length' value={length} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.length ? <div className='text-danger'>{props?.errors?.length }</div> :"" }
                        
                        </div>
                        <div className="col-md-3">
                            <label className="form-label"> Breadth <span className='text-muted'> (in cm)</span></label>
                            <input type="text" className="form-control" name='breadth' value={breadth} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.breadth ? <div className='text-danger'>{props?.errors?.breadth }</div> :"" }
                        
                        </div>
                        <div className="col-md-3">
                            <label className="form-label"> Height <span className='text-muted'> (in cm)</span></label>
                            <input type="text" className="form-control" name='height' value={height} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.height ? <div className='text-danger'>{props?.errors?.height }</div> :"" }
                        
                        </div>
                        <div className="col-md-3">
                            <label className="form-label"> Weight<span className='text-muted'> (in kg)</span></label>
                            <input type="text" className="form-control" name='weight' value={weight} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.weight ? <div className='text-danger'>{props?.errors?.weight }</div> :"" }
                       
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Description</label>
                            <textarea type="text" className="form-control" name='description' value={description} onChange={(e) => {props.onChange(e) }} ></textarea>
                        </div>
                        <div className="col-md-12">
                            <label className="form-label"> MRP</label>
                            <input type="number" className="form-control" name='MRP' value={MRP} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.MRP ? <div className='text-danger'>{props?.errors?.MRP }</div> :"" }
                       
                        </div>
                        <div className="col-md-12">
                            <label className="form-label"> Best Price</label>
                            <input type="number" className="form-control" name='bestPrice' value={bestPrice} onChange={(e) => {props.onChange(e) }} />
                            {props?.errors?.bestPrice ? <div className='text-danger'>{props?.errors?.bestPrice }</div> :"" }
                        
                        </div>
                      {(props?.user?.role==="RESELLER" || props?.user?.role==="ADMIN") ?  <div className="col-xl-12 col-lg-12">
                        <div className="card-body m-0 p-0">
                            <label className="form-label">Brand</label>
                            <select className="form-select" name='brandName' value={brandName} onChange={(e)=>props.onChange(e)}  >
                                <option value="" selected>Choose Brand</option>
                                {props?.brands?.filter(f1=>f1?.approval==="APPROVED")?.map(c1=>
                                    <option value={c1.brandName} >{c1.brandName}</option>
                                    )}
                            </select>
                        </div>
                    </div> : ""}
                        <div className="col-xl-12 col-lg-12">
                        <div className="card-body m-0 p-0">
                            <label className="form-label">Category</label>
                            <select className="form-select" name='category' value={category} onChange={(e)=>props.onChange(e)}  >
                                <option value="" selected>Choose Category</option>
                                {categories1?.filter(f1=>(f1?.status==="ACTIVE"))?.map(c1=>
                                    <option value={c1.categoryName} >{c1.categoryName}</option>
                                    )}
                            </select>
                            {props?.errors?.category ? <div className='text-danger'>{props?.errors?.category }</div> :"" }
                        </div>
                    </div>
                        <div className="col-xl-12 col-lg-12">
                        <div className="card-body m-0 p-0">
                            <label className="form-label">Product Model</label>
                            <select className="form-select" name='productModel' value={productModel} onChange={(e)=>props.onChange(e)}  >
                                <option value="" selected>Choose Model</option>
                                {category && products1.map(c1=>
                                    <option value={c1.productName} >{c1.productName}</option>
                                    )}
                            </select>
                            {props?.errors?.productModel ? <div className='text-danger'>{props?.errors?.productModel }</div> :"" }

                        </div>
                    </div>
                        <div className="col-xl-12 col-lg-12">
                        <div className="card-body m-0 p-0">
                            <label className="form-label">Fault Type </label>{" "} <span className='text-muted'>(Select max 5)</span>
                            <select className="form-select" name='fault' value={fault} onChange={(e)=>fault1(e.currentTarget.value)}  >
                                <option value="" selected>Choose Fault</option>
                                {productModel && props?.faultType?.filter(f1=>f1?.productModel===productModel)?.map(c1=>
                                    <option value={c1.faultName} >{c1.faultName}</option>
                                    )}
                            </select>
                        </div>
                        {faultType?.map((f1,i)=><div className='btn btn-sm bg-dark text-white m-1'>{f1} <i className='ms-2 icofont-close-circled' onClick={(e)=> handleIndex(i)}></i></div>)}
                    </div>
                    {/* <div className="col-xl-12 col-lg-12">
                        <div className="card-body m-0 p-0">
                            <label className="form-label">Technician Price </label>
                            <select className="form-select" name='technician' value={technician} onChange={(e)=>props.onChange(e)}  >
                                <option value="" selected>Select Price</option>
                                {technicianPrice?.map(t1=>
                                    <option value={t1} >{t1}</option>
                                    )}
                            </select>
                        </div>
                    </div> */}
                        
                    </div>
                </form>
            </div>
        </>
    )
}
export default BasicInformation